import React, { Component } from 'react'

export default class ShareButton extends Component {
  render () {
    return (
      <div className='btn-share'>
        <span className='social_share text-dark' />
      </div>
    )
  }
}
